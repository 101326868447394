// 
// Custom-radio.scss
//


// checkbox & radio color
@each $color, $value in $theme-colors {
    .form-check {
        &.form-check-#{$color} {
            .form-check-input:checked {
                background-color: $value !important;
                border-color: $value !important;
            }
        }
    }
}    


// dark mode
body[data-layout-color="dark"] {
    @each $color, $value in $dark-theme-colors {
        .form-check {
            &.form-check-#{$color} {
                .form-check-input:checked {
                    background-color: $value !important;
                    border-color: $value !important;
                }
            }
        }
    } 
}
